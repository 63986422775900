.about-root-container {
  background-color: #284286;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}
.title {
  font-family: Means !important;
  font-size: 32px !important;
  color: #fff;
  font-weight: 600 !important;
}
.paragraphs {
  color: #fff;
  padding: 0px 50px;
  font-size: 22px !important;
  line-height: 1.7;
}
