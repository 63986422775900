.faq-help-root-container {
  background-color: #284286;
  height: 100%;
  display: flex;
  width: 100vw;
  flex-direction: column;
  gap: 40;
}
.faq-inner-container {
  width: 93vw;
  margin: 40px auto;
}
.faq-title {
  font-family: Means !important;
  font-size: 32px !important;
  color: #fff;
  font-weight: 600 !important;
}
.category-top {
  align-items: center;
  color: #011b60 !important;
  margin: 40px 0px;
}

.category-btn {
  background-color: #ffffff;
  padding: 2px 5px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  padding: 7px 30px;
  border: none;
  border-style: none;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  font-size: 18px;
  margin-right: 30px;
  cursor: pointer;
}

.category-btn-selected {
  background-color: #ffc60c;
  padding: 2px 5px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  padding: 7px 30px;
  border: none;
  border-style: none;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  font-size: 18px;
  margin-right: 30px;
  cursor: pointer;
}

.top-category-cont {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.faq-main-section-container {
  width: 100%;
  display: flex;
  gap: 50px;
  overflow: auto;
  height: calc(100vh - 300px);
}
.faq-left-side {
  width: 50%;
  color: #011b60;
}
.faq-right-side {
  width: 50%;
  color: #011b60;
}

.question-main-container {
  margin-top: 30px;
}

.drop-down-icon {
  font-size: 15px;
  color: #152d6e;
}

.faq-question-text {
  color: #011b60;
  font-weight: 500;
  font-size: 18px;
}

.faq-answer-text {
  color: #011b60;
  font-size: 15px;
  line-height: 2em;
}
