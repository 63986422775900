.download-modal-container {
  font-size: 14px;
  line-height: 2;
  background-color: #fff;
  width: 40vw;
  padding: 20px;
}

.icons-container {
  width: 150px;
  height: 150px;
}
.csv-pdf-icon {
  width: 100%;
  height: 100%;
}
