.show-more-container {
  font-size: 14px;
  line-height: 2;
  background-color: #fff;
  width: 60vw;
  padding: 20px;
}

.mid-contents-text {
  line-height: 2;
}

.show-button {
  font-size: 13px;
  color: #011b60;
  cursor: pointer;
  background-color: #f4f5f9;
  border-radius: 10px;
  padding: 0px 3px;
}
