.period-root-container {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
}
.inner-root-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 180px);
  flex: 1;
  position: relative;
}

.cancel-selection-btn {
  position: absolute !important;
  top: -10px;
  right: -10px;
  color: red !important;
}

.periods-selections-wrapper {
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 30%;
  height: calc(100vh - 250px);
  margin-left: 5%;
}
.selection-container {
  background-color: #f4f5f9;
  padding: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.select-label {
  color: #011b60;
  font-size: 16px;
  font-weight: 500;
}
.select-dropdown {
  width: 100%;
  border: none;
}
/* style ppty defined by Select component */
.css-13cymwt-control {
  border: none !important;
  border-radius: 0px !important;
}

.css-t3ipsp-control {
  border: none;
  border-radius: 0px !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.category-container {
  flex: 1;
  border-radius: 0;
  background-color: #fff;
}
.empty-category-container {
  flex: 1;
  border-radius: 0;
}
.compare-var-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-top: 80px;
}
.compare-var-icon {
  height: 15px !important;
  width: 15px !important;
  color: #011b60;
}
.compare-var-text {
  color: #011b60;
  text-decoration-line: underline;
  font-size: 14px;
}
.header-text-container {
  width: 90%;
  margin: 20px auto;
}
.header-text {
  color: #011b60;
  font-size: 18px;
  font-size: 500;
}

.rdrDefinedRangesWrapper {
  display: none;
}
