.location-root-container {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
}
.inner-root-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 180px);
  flex: 1;
  position: relative;
}

.cancel-selection-btn {
  position: absolute !important;
  top: -10px;
  right: -10px;
  color: red !important;
}

.locations-selections-wrapper {
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 90%;
  height: calc(100vh - 250px);
  margin: auto;
}
.selection-container {
  width: 100%;
  background-color: #f4f5f9;
  padding: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.select-label-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.select-label {
  color: #011b60;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.select-dropdown {
  width: 100%;
  border: none;
}
/* style ppty defined by Select component */
.css-13cymwt-control {
  border: none !important;
  border-radius: 0px !important;
}

.css-t3ipsp-control {
  border: none;
  border-radius: 0px !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.category-container {
  flex: 1;
  border-radius: 0;
  background-color: #fff;
  width: 100%;
}
.empty-category-container {
  flex: 1;
  border-radius: 0;
}
.compare-var-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-top: 80px;
}
.compare-var-icon {
  height: 15px !important;
  width: 15px !important;
  color: #011b60;
}
.compare-var-text {
  color: #011b60;
  text-decoration-line: underline;
  font-size: 14px;
}
.header-text-container {
  width: 90%;
  margin: 20px auto;
}
.header-text {
  color: #011b60;
  font-size: 18px;
  font-size: 500;
}

.facilities-section-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.empty-facility-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
  color: #011b60;
}
.facilities-head-text {
  color: #011b60;
  font-size: 14px;
  font-weight: 500;
  margin: 20px;
}
.facilities-container {
  background-color: #f4f5f9;
  padding: 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 0;
  height: calc(100vh - 500px);
}
.facility-container {
  background-color: #f4f5f9;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  gap: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.facility-text {
  font-size: 14px;
  color: #011b60;
  text-align: left;
}
