.summary-root-container {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100vh - 125px);
  position: relative;
}

.summary-left {
  width: 65%;
  background-color: #f4f5f9;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 40px;
}
.graph-container-box {
  width: 95%;
  height: 50vh;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 25px 35px;
}

.graph-main-inner-container {
  height: 100%;
  width: 70%;
  overflow-x: auto;
}

.graph-info-container {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20;
  padding: 25px 5px;
}

.graph-info-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
}
.indicator-one-bullet {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: #152d6e;
}
.indicator-bullet {
  height: 15px;
  width: 15px;
  border-radius: 15px;
}
.indicator-two-bullet {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: #ffc60c;
}
.indicator-three-bullet {
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: #ffc4c4;
}

.indicator-text {
  font-size: 12px;
  width: 90%;
  margin-left: 10px;
}
.summary-right {
  width: 35%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: #011b60;
}

.tab-switch-container {
  height: 10vh;
  background-color: #ffc60c;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.contents-section-container {
  padding: 25px;
  height: calc(100vh - 280px);
  overflow: auto;
  width: 100%;
}

.mid-contents-text {
  font-size: 14px;
  line-height: 2;
}

.point-paragraph {
  font-size: 14px;
  line-height: 2;
}

.bottom-container {
  height: 10vh;
  background-color: #152d6e;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.back-dashboard-button {
  background-color: #ffc60c;
  width: 35%;
  padding: 10px;
  cursor: pointer;
  border-style: none;
  border-radius: 3px;
  font-weight: 500;
  color: #011b60;
}

.share-feedback-button {
  background-color: #fff;
  width: 35%;
  padding: 10px;
  cursor: pointer;
  border-style: none;
  border-radius: 3px;
  font-weight: 500;
  color: #011b60;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
}

.export-button {
  background-color: #fff !important;
  width: 35px;
}

.tab-button {
  background-color: #ffc60c;
  width: 35%;
  padding: 10px;
  cursor: pointer;
  border-style: none;
  border-radius: 3px;
  font-weight: 500;
  color: #011b60;
}

.active-tab-button {
  background-color: #fff;
  width: 35%;
  padding: 10px;
  cursor: pointer;
  border-style: none;
  border-radius: 3px;
  font-weight: 600;
  color: #011b60;
}

.location-duration-title {
  font-weight: 600;
  font-size: 13px;
}
.insight-recommendation-root {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.insight-recommendation-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.insight-recommendation-content {
}
.element-title-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.element-text {
  font-weight: 500;
}

.element-value-text {
  font-weight: 600;
  margin-left: 5px;
}
.main-category-title {
  text-align: center;
  font-size: 600;
  font-size: 22px;
  text-decoration: underline;
}
