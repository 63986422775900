.first-time-container {
  background-color: #fff;
  width: 50vw;
  height: 80vh;
  padding: 16px;
  color: #011b60;
}
.first-time-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-title {
  font-family: Means;
  margin-bottom: 16px;
}
.progress-step-container {
  display: flex;
  justify-content: space-evenly;
  background-color: #284286;
  padding: 10px;
}
.progress-step {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-size: 13px;
  color: #fff;
}

.active {
  color: #ffc60c;
  font-weight: 600;
}
.step-container {
  width: 100%;
  height: 100%;
  background-color: #f4f5f9;
}
.step-title {
  display: flex;
  justify-content: center;
  padding-top: 8px;
  font-size: large;
  font-weight: 500;
  margin-bottom: 12px;
}
.step-container .step-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.main-container {
  display: flex;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 4px;
  height: 47vh;
}

.next-prev-button {
  border: none;
  padding: 8px 15px;
  border-radius: 3px;
  cursor: pointer;
}

.end-btn {
  background-color: red;
  color: #fff;
}
.nxt-btn {
  background-color: green;
  color: #fff;
}

.prev-btn {
  background-color: #011b60;
  color: #fff;
}

.next-prev-button :hover {
  background-color: #fff;
  color: #ffc60c;
}
