.topbarAvatar {
  position: "relative";
  background: "red";
  cursor: pointer;
}
.topbarMenu {
  position: absolute;
  z-index: 200;
  padding: 1rem;
  top: 4rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background-color: #f4f5f9;
  border-radius: 5px;
}
