.FeedbackModal_Wrapper {
  display: flex !important;
  flex-direction: column;
  gap: 0.7rem;
  justify-content: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
  border: none;
  max-height: 80vh;
  overflow: auto;
  width: 450px;
}
.FeedbackModal_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FeedbackModal_header > span {
  color: #011b60;
  font-weight: 600;
  font-size: 20px;
}

.FeedbackModal_ratingEmoji {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.FeedbackModal_ratingEmoji > img {
  object-fit: contain;
  width: 90%;
}

.FeedbackModal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
}

.FeedbackModal_body > span {
  color: #011b60;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-around;
  flex-shrink: 2;
}

.FeedbackModal_form {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.FeedbackModal_form > textarea {
  color: #011b60;
  padding: 0.5rem;
  border: 0.5px solid rgba(21, 45, 110, 0.4);
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  font-size: 16px;
  resize: none;
}

.FeedbackModal_buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.FeedbackModal_leftBtn {
  padding: 0.7rem 1.5rem;
  border: 1px solid #011b60;
  background-color: #fff;
  color: #011b60;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 600;
}
.FeedbackModal_rightBtn {
  padding: 0.7rem 2rem;
  background-color: #152d6e;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
