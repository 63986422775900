*{
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Avenir", "Means", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* For webkit-based browsers */
/* Hide the scrollbar track */
::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir"),
    url(./assets/fonts/Avenir/Avenir-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-weight: 500;
  src: local("Avenir"),
    url(./assets/fonts/Avenir/Avenir-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Avenir";
  font-weight: 900;
  src: local("Avenir"),
    url(./assets/fonts/Avenir/Avenir-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Means";
  src: local("Means"),
    url(./assets/fonts/Means/Means-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Means";
  font-weight: 500;
  src: local("Means"),
    url(./assets/fonts/Means/Means-Regular.ttf) format("truetype");
}
