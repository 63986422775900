.home-root {
  background-color: #284286;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.home-left {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 100%;
}

.map-outer {
  /* stroke: #000; */
  width: 100%;
  height: auto;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  z-index: 2;
  height: 100%;
}

.location-area {
  /* fill: #fff; */
  cursor: pointer;
  fill: transparent;
}
.location-area:hover,
.location-area:focus {
  /* fill: #59b952; */
  outline: 0;
  fill: rgba(255, 198, 12, 0.5);
}
.location-area[aria-checked="true"] {
  /* fill: #f4bc44; */
  outline: 0;
  fill: rgba(89, 185, 82, 0.5);
}
.home-right {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: left;
  gap: 15px;
  margin-bottom: 2vh;
  padding-right: 30px;
  position: relative;
}
.map-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.map-select-container {
  position: absolute;
  bottom: 10vh;
  right: 5%;
  width: 250px;
}
.map-select-text {
  color: #fff;
  font-size: 14px !important;
  text-align: left;
}
.welcome-text {
  font-size: 40px !important;
  font-family: Means !important;
  font-weight: 500 !important;
  color: #ffc60c;
  text-align: left;
}
.description {
  color: #fff;
  font-size: 17px !important;
  text-align: left;
  padding-right: 50px;
}
.about-btn {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}
.news-item-container {
  padding-top: 40px;
}
.news-itemInner-container {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
  overflow: hidden;
  width: 500;
  height: 70px;
  gap: 10px;
}

.carousel {
  width: 320px;
}
.img-container {
  height: 50px;
  width: 100%;
  position: relative;
}
.carousel-img {
  width: 100%;
  display: block;
  height: 100%;
}
.description-container {
  overflow: hidden;
}
.news-description {
  font-size: 12px !important;
  height: 50px;
  width: 700px;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
  overflow: hidden;
}
.card {
  height: 150px;
  box-sizing: content-box;
}
.left-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
  /* margin-top: 30vh; */
}
.social-connect-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
}
.soc-icon-btn {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background-color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.soc-icon {
  width: 20px !important;
  height: 20px !important;
  color: #011b60;
}
