.myProfileModel_wrapper {
  display: flex !important;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
  border: none;
  max-height: 80vh;
  overflow: auto;
}
.form-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.myProfileModel_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myProfileModel_header > span {
  color: #011b60;
  font-weight: 800;
  font-size: 20px;
}

.myProfileModel_profileUploadWrapper {
  display: flex;
  width: 100%;
}

.myProfileModel_formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}

.myProfileModel_uploadWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: center;
}

.myProfileModel_uploadLabel {
  color: #011b60;
  font-size: 12px;
  font-weight: 600;
}
.myProfileModel_uploadInput {
  color: #011b60;
  width: 100px;
  overflow: hidden;
  display: block;
}
.myProfileModel_uploadInput::-webkit-file-upload-button {
  color: #011b60;
  background-color: #eff1f6;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: none;
}

.myProfileModel_textWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-content: space-between;
  gap: 1.5rem;
}

.myProfileModel_inputWrapper {
  width: fit-content;
  display: flex;
  flex-direction: column;
  color: #011b60;
  gap: 0.5rem;
}
.fullname-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #011b60;
  gap: 0.5rem;
}
.myProfileModel_inputWrapper > label {
  font-size: 10px;
  font-weight: 600;
}

.myProfileModel_inputWrapper > input {
  color: #011b60;
  padding: 0.5rem 0.5rem;
  border-radius: 0%;
  border: 0.5px solid rgba(21, 45, 110, 0.4);
}

.myProfileModel_buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.myProfileModel_leftBtn {
  padding: 0.7rem 1.5rem;
  border: 1px solid #011b60;
  background-color: #fff;
  color: #011b60;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 600;
}
.myProfileModel_rightBtn {
  padding: 0.7rem 2rem;
  background-color: #152d6e;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
