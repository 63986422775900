.page-wrapper-root {
  background-color: #fff;
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20;
}

.steps-container {
  background-color: #284286;
  height: 70px;
  padding: 15px 100px;
}

.css-z7uhs0-MuiStepConnector-line {
  border: 1px dashed #fff !important;
}
.page-footer {
  height: 50px;
  width: 100%;
  background-color: #ffc60c;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
}

.continue-btn {
  height: 35px;
  color: #284286;
  background-color: #fff;
  margin-left: auto;
  margin-right: 50px;
  border: none;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  cursor: pointer;
}
