.login-root-container {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: #284285;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.Login_Wrapper {
  background-image: url("../../assets/images/Map.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 140%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.Login_Card_Background {
  background-color: #ffc60c;
  position: relative;
  border: 5px;
  width: 400px;
  height: 500px;
  border-radius: 10px;
  margin-right: 10%;
}
.Login_Card {
  background-color: #284285;
  border: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  gap: 2rem;
  height: inherit;
  position: absolute;
  top: -10px;
  left: -10px;
  padding: 50px;
}

.Login_Header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  margin-bottom: 10px;
}

.Login_Header_Logo {
  font-size: 35px;
  letter-spacing: 2px;
  font-weight: 600;
  font-family: "Means";
}

.Login_Header_FormTitle {
  font-size: 20px;
  font-weight: 400;
}

.Login_Form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.Login_TextWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.3rem;
}

.Login_LoginBtn {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 2px;
  color: #284285;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  height: 40px;
}

.Login_Form_Label {
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  margin-left: 15px;
}

.Login_Form_Input {
  background-color: #fff !important;
  width: 100%;
  border: 0.5px solid #fff;
  height: 40px;
  padding-left: 15px;
  border: 0.5px solid #fff;
}

.Login_Form_ForgottenPassword {
  display: flex;
  align-items: center;
  justify-content: right;
  color: #fff;
  font-size: 14px;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
}
