.base-component-root {
  background-color: #fff;
  display: flex;
  flex-direction: row;
}
.program_areas_container {
  background-color: #f4f5f9;
  width: 20%;
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  gap: 15px;
  margin-left: 5%;
  overflow-y: auto;
}
.selceted-program-area {
  border: 1.5px solid #152d6e;
}
.individual_area_container {
  background-color: #fff;
  display: flex;
  padding: 15px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.inactive-area {
  opacity: 0.7;
}

.area_text {
  color: #011b60;
  font-size: 13px;
}

.program-area-right {
  width: 70%;
  display: flex;
  height: calc(100vh - 165px);
  flex-direction: column;
  overflow-x: hidden;
  box-sizing: content-box;
  flex: 1;
}
.program-area-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -5px;
}

.program-area-title {
  font-size: 24px;
  color: #011b60;
  font-weight: 500;
  margin-top: 30px;
  margin-left: 50px;
}
.compare-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  margin-right: 30px;
  margin-top: 10px;
}
.compare-icon {
  height: 15px !important;
  width: 15px !important;
  color: #011b60;
}

.reset-indicator-icon {
  height: 15px !important;
  width: 15px !important;
  background-color: #011b60;
  color: #fff;
  border-radius: 15px;
}
.compare-text {
  color: #011b60;
  text-decoration-line: underline;
  font-size: 14px;
}

.indicators_inner_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;
  margin: 25px 0;
}

.indicators-container {
  display: flex;
  justify-content: space-evenly;
}

.indicator-item-container {
  background-color: #f4f5f9;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 10px;
  gap: 10px;
  height: 50px !important;
  cursor: pointer;
}
.indicator-text {
  font-size: 14px;
  color: #011b60;
  text-align: left;
}
.selceted-indicators-container {
  /* background-color: #f4f5f9; */
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40%;
  gap: 10px;
  height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;
  margin: 60px 30px 25px 0;
  padding-right: 20px;
}

.selected-indicator-item {
  background-color: #f4f5f9;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 10px;
  gap: 10px;
  cursor: pointer;
}

.empty-container {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
